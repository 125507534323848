import React from "react";
import { Link as GatsbyLink, graphql, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { HiOutlineArrowNarrowRight } from "@react-icons/all-files/hi/HiOutlineArrowNarrowRight";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import ResponsiveHeader from "../components/responsiveheader";
import Button from "../components/button";
import FadeInSection from "../components/fadeinsection";

import { BG_IMG_CLASSES } from "../helpers/utils";

const BlogPage = ({ data }) => {
  const { allMarkdownRemark } = data;
  const pinnedPost = allMarkdownRemark.edges.find(
    ({ node }) => node.frontmatter.pinned
  ).node;
  const pinnedImage = getImage(pinnedPost.frontmatter.image);

  return (
    <Layout>
      <SEO
        title="The Top Dog Blog"
        description="The latest news and insights from the Top Dog team."
      />

      <FadeInSection>
        <div className="w-full grid mx-auto mb-6">
          <GatsbyImage
            image={pinnedImage}
            alt={pinnedPost.frontmatter.title}
            className={`${BG_IMG_CLASSES} opacity-75`}
            loading="eager"
            style={{ gridArea: "1/1" }}
          />
          <div
            className={`absolute inset-0 bg-gradient-to-t from-black to-transparent ${BG_IMG_CLASSES}`}
            style={{ gridArea: "1/1" }}
          ></div>

          <div
            className="grid w-full content-start relative max-w-screen-lg"
            style={{ gridArea: "1/1" }}
          >
            <p className="mb-1">
              <span className="text-sm uppercase font-bold py-1 px-2 bg-yellow-500 rounded-md">
                HOT POST
              </span>
            </p>
            <ResponsiveHeader className="uppercase" size={3}>
              {pinnedPost.frontmatter.title}
            </ResponsiveHeader>
            <p className="font-medium text-lg lg:text-xl">
              {pinnedPost.frontmatter.summary}
            </p>
            <p className="text-gray-400 text-sm">
              Published {pinnedPost.frontmatter.date} by{" "}
              {pinnedPost.frontmatter.author}
            </p>
            <p className="mt-6">
              <Button action={pinnedPost.fields.slug} theme="blue">
                Continue reading{" "}
                <HiOutlineArrowNarrowRight className="inline" size="15pt" />
              </Button>
            </p>
          </div>
        </div>
      </FadeInSection>

      <ResponsiveHeader size="1" className="mb-12">
        More from the Top Dog Blog
      </ResponsiveHeader>

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {allMarkdownRemark.edges.map(({ node }, index) => {
          const image = getImage(node.frontmatter.image);

          return (
            <FadeInSection
              key={node.fields.slug}
              delay={`${0.1 * (index + 1)}s`}
            >
              <div
                className="flex flex-col h-full border border-gray-200 cursor-pointer levitate"
                onClick={() => navigate(node.fields.slug)}
              >
                <div className="w-full pointer-events-none select-none">
                  <GatsbyImage
                    image={image}
                    alt="Feature Image"
                    className="w-full h-48"
                  />
                </div>

                <div className="w-full flex-grow px-4 md:px-8">
                  <p className="text-gray-400 text-sm uppercase my-6">
                    Published {node.frontmatter.date}
                  </p>

                  <ResponsiveHeader size={1} className="font-bold uppercase">
                    <GatsbyLink to={node.fields.slug} className="text-white no-underline">
                      {node.frontmatter.title || node.fields.slug}
                    </GatsbyLink>
                  </ResponsiveHeader>

                  <p className="my-6 text-justify">
                    {node.frontmatter.summary}
                  </p>
                </div>

                <div className="items-end mb-4 ml-4 md:mb-8 md:ml-8">
                  <Button action={node.fields.slug}>
                    Continue reading{" "}
                    <HiOutlineArrowNarrowRight className="inline" size="15pt" />
                  </Button>
                </div>
              </div>
            </FadeInSection>
          );
        })}
      </div>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: { visible: { ne: false } }
        fields: { collection: { eq: "blog" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            author
            pinned
            summary
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
    }
  }
`;
